.avatars {
  display: flex;
  list-style-type: none;
  flex-direction: row;	
}

.avatarItem {
    border: 1px solid #000000;
    
    color: #ffffff;
    display: block;
    font-family: sans-serif;
    font-size: 12px;
    font-weight: 100;    
    text-align: center;
    transition: margin 0.1s ease-in-out;
    overflow: hidden;
    margin-left: -27px;
}